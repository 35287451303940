import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import { createMuiTheme } from '@material-ui/core/styles';
import DotimatrixEot from './styles/fonts/Dotimatrix-5.ttf.eot';
import DotimatrixWoff from './styles/fonts/Dotimatrix-5.ttf.woff';
import DotimatrixSvg from './styles/fonts/Dotimatrix-5.ttf.svg';

import BrutalTypeRegularEot from './styles/fonts/BrutalType-Regular.eot';
import BrutalTypeRegularTtf from './styles/fonts/BrutalType-Regular.ttf';
import BrutalTypeRegularSvg from './styles/fonts/BrutalType-Regular.svg';
import BrutalTypeRegularWoff from './styles/fonts/BrutalType-Regular.woff';
import BrutalTypeRegularWoff2 from './styles/fonts/BrutalType-Regular.woff2';

import BrutalTypeBoldEot from './styles/fonts/BrutalType-Bold.eot';
import BrutalTypeBoldTtf from './styles/fonts/BrutalType-Bold.ttf';
import BrutalTypeBoldSvg from './styles/fonts/BrutalType-Bold.svg';
import BrutalTypeBoldWoff from './styles/fonts/BrutalType-Bold.woff';
import BrutalTypeBoldWoff2 from './styles/fonts/BrutalType-Bold.woff2';

const dotimatrix = {
  fontFamily: 'Dotimatrix',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
      local('Dotimatrix'),
      local('Dotimatrix-Regular'),
      url('${DotimatrixWoff}') format('woff'),
      url('${DotimatrixSvg}#Dotimatrix-5') format('svg'),
      url('${DotimatrixEot}'),
      url('${DotimatrixEot}?#iefix') format('embedded-opentype')
  `
};

const brutalTypeRegular = {
  fontFamily: 'Brutal Type',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url('${BrutalTypeRegularEot}?#iefix') format('embedded-opentype'),
    url('${BrutalTypeRegularWoff2}') format('woff2'),
    url('${BrutalTypeRegularWoff}') format('woff'),
    url('${BrutalTypeRegularTtf}.ttf') format('truetype'),
    url('${BrutalTypeRegularSvg}#BrutalType') format('svg');
  `
};

const brutalTypeBold = {
  fontFamily: 'Brutal Type Bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url('${BrutalTypeBoldEot}?#iefix') format('embedded-opentype'),
    url('${BrutalTypeBoldWoff2}') format('woff2'),
    url('${BrutalTypeBoldWoff}') format('woff'),
    url('${BrutalTypeBoldTtf}.ttf') format('truetype'),
    url('${BrutalTypeBoldSvg}#BrutalType') format('svg');
  `
};


// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [dotimatrix, brutalTypeRegular, brutalTypeBold],
      },
    },
    MuiSelect: {
      root: {
        // backgroundColor: '#fff',
        color: '#fff',
      },
      select: {
        color: '#fff',
      }
    },
    MuiButton: {
      root: {
        fontSize: '1.2em',
        borderRadius: '24px',
        paddingLeft: 10,
        paddingRight: 10
      },
      contained: {
        color: '#fff',
        fontWeight: 'bold',
        background: 'linear-gradient(90deg, #7193ea 0, #5e3df9 51%, #7193ea)',
      },
      containedPrimary: {
        color: '#fff'
      },
      outlinedSecondary: {
        color: '#fff'
      },
      text: {
        color: '#ff8686'
      },
      textSecondary: {
        color: '#ff8686',
        paddingLeft: 20,
        paddingRight: 20
      }
    },
    MuiDialog: {
      root: {
        backgroundColor: '#fff',
        color: '#000'
      }
    },
    MuiAlert: {
      root: {
        border: '1px solid #ff8686'
      },
      message: {
        padding: 0
      }
    },
    MuiMenuItem: {
      root: {
        backgroundColor: '#fff',
        color: '#000',
      },
    }
  },
  typography: {
    allVariants: {
      color: '#fff',
      fontFamily: 'Brutal Type, sans-serif',
    },
    // fontFamily: 'Raleway, Arial'
    button: {
      fontFamily: 'Dotimatrix, sans-serif',
      color: '#fff'
    },
    h1: {
      fontSize: '2.5rem',
      fontFamily: 'Brutal Type Bold, sans-serif',
      paddingBottom: 0,
      marginBottom: '0.75em',
      //borderBottom: '1px solid #81d644',
      textAlign: 'center',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '1.5rem',
      fontFamily: 'Dotimatrix, sans-serif',
      paddingBottom: 0,
      marginBottom: '0.75em',
      //borderBottom: '1px solid #81d644',
      textAlign: 'center'
    },
    h5: {
      fontSize: '1.2rem',
      fontFamily: 'Dotimatrix, sans-serif',
      marginTop: '1.5em',
      marginBottom: '1em',
    },
  },
  shape: {
    borderRadius: 4,
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#000',
    },
    info: {
      main: '#000',
    },
    error: {
      main: red.A400,
    },
    grey: grey,
    background: {
      default: '#000',
      paper: '#000',
    },
    warning: {
      main: '#ff8686'
    }
  },
});

export default theme;
